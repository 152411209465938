<template>
  <div>
    <div class="page-container pb-0 mb-2">
      <filter-component
        @filterDistrict="handleFilter($event)"
      />
    </div>
    <div class="page-container-table">
      <!--Phần header của bảng -->
      <button-all-header
        :hideDelete="false"
        :hideImportFile="false"
        :hideDowload="false"
        :hideAdd="false"
        class="custtom-header pr-1"
        @clickExportExcel="dowloadFile"
        @search="search($event)"
      />
      <!--Phần header của bảng -->
      <vue-good-table
        ref="user-table"
        style-class="vgt-table"
        :columns="columns"
        :rows="dataList || []"
        :select-options="{
          enabled: false,
        }"
        :sort-options="{
          enabled: false,
        }"
      />
      <my-pagination
        :totalItems="totalRecord"
        :currentPage="urlQuery.pageNumber"
        @pageClick="pageChange"
      />
    </div>
  </div>

</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ButtonAllHeader from '@/views/common/ButtonAllHeader.vue'
import VueGoodTable from '@/components/table/VueGoodTable.vue'
import MyPagination from '@/components/pagination/MyPagination.vue'
import store from '../../store'
import FilterComponent from './component/FilterComponent.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../constants/ConstantsApi'

export default {
  components: {
    ButtonAllHeader,
    VueGoodTable,
    MyPagination,
    FilterComponent,
  },
  data() {
    return {
      // ==dữ liệu bảng
      dataList: [],
      columns: [
        {
          label: 'LOẠI BỆNH NGHỀ NGHIỆP',
          field: 'name',
        },
        {
          label: 'SỐ NGƯỜI MẮC BỆNH HIỂM NGHÈO',
          field: 'value',
        },
        {
          label: 'NĂM',
          field: 'year',

        },
      ],
      // ==phân trang
      urlQuery: {
        search: '',
        year: 2021,
        pageSize: 10,
        pageNumber: 1,
      },
      yearPresent: 0,
      totalRecord: 0,
      currentPage: 10,
    }
  },
  computed: {
    ...mapGetters('fiterYear', ['year']),
  },
  created() {
    this.getYear()
    this.urlQuery.year = this.year
    this.fetchData(this.urlQuery)
  },
  methods: {
    ...mapActions('fiterYear', ['getYear']),
    async dowloadFile() {
      const payload = {
        year: this.urlQuery.year,
      }
      const name = 'BenhNgheNghiepCu.xlsx'
      await store.downloadExportFile(name, ConstantsApi.DOWLOAD_EXCEL, payload)
    },
    async fetchData(urlQuery) {
      this.$showLoading()
      const { data } = await axiosApiInstance.get(ConstantsApi.API_DISEASE_OLD, {
        params: urlQuery,
      })
      this.dataList = data?.data?.pageLists
      this.totalRecord = data?.data?.totalRecord
      this.$hideLoading()
    },

    search(val) {
      if (val != null) {
        this.urlQuery.pageNumber = 1
        this.urlQuery.search = val
        this.fetchData(this.urlQuery)
      } else {
        this.urlQuery.pageNumber = 1
        this.urlQuery.search = ''
        this.fetchData(this.urlQuery)
      }
    },

    // xử lý filter
    handleFilter(val) {
      if (val != null) {
        this.urlQuery.pageNumber = 1
        this.urlQuery.year = val
        this.fetchData(this.urlQuery)
      } else {
        this.urlQuery.pageNumber = 1
        this.urlQuery.year = this.year - 1
        this.fetchData(this.urlQuery)
      }
    },

    pageChange(pageNumber, pageSize) {
      this.urlQuery.pageNumber = pageNumber
      this.urlQuery.pageSize = pageSize
      this.fetchData(this.urlQuery)
    },
  },
}
</script>

<style>

</style>
